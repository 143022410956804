import React, { Component, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { addResponsivity, up, down, between } from '../lib/styles'
import { generateArticlePath } from '../lib/utils'

import { extractNodesFromData } from '../lib/helpers'
import ArticlesSuggestions from './ArticlesSuggestions'
import { Page, PageContent } from './Page'

import BgCircle from '../data/images/bg-circle.inline.svg'

const Wrapper = styled.div`
  ${({
    theme: { colors, pageSidePadding, mobilePageSidePadding },
    withoutColor,
    noPadding,
  }) => css`
    width: 100%;
    background: ${!withoutColor && colors.cyan};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    ${!noPadding &&
    css`
      padding: 24px ${withoutColor ? 0 : mobilePageSidePadding} 122px;
    `}

    ${up('mobile')} {
      ${!noPadding &&
      css`
        padding: 56px ${withoutColor ? 0 : pageSidePadding} 122px;
      `}
    }
  `}
`

const StyledBgCircle = styled(BgCircle)`
  ${({ theme: { colors } }) => css`
    /* z-index: -1; */
    max-width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${up('mobile')} {
    }
  `}
`

function SuggestionsFooter({
  withoutColor,
  hp,
  hideQuiz,
  hideArticle,
  noPadding,
}) {
  // const [mounted, setMounted] = useState(false)

  // const mountedRef = useRef(false)
  // useEffect(() => {
  //   if (!mountedRef.current) {
  //     setMounted(true)
  //     mountedRef.current = true
  //   }
  // }, [])

  return (
    <Wrapper withoutColor={withoutColor} noPadding={noPadding || hp}>
      {/* {mounted && <StyledBgCircle />} */}
      <StyledBgCircle />
      <PageContent>
        <ArticlesSuggestions
          hp={hp}
          hideQuiz={hideQuiz}
          hideArticle={hideArticle}
        />
      </PageContent>
    </Wrapper>
  )
}

export default SuggestionsFooter
