import React, { Component, Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { addResponsivity, up, down, between } from '../lib/styles'
import { generateArticlePath } from '../lib/utils'

import Button from './Button'
import Col from './Col'
import Row from './Row'
import Gap from './Gap'
import Link from './Link'

import { extractNodesFromData, shuffleArray } from '../lib/helpers'
import ArticleLinkBox from './ArticleLinkBox'
import questionMarkPath from '../data/images/question-mark.svg'
import podcastIconDesktopPath from '../data/images/podcast-icon-desktop.svg'
import podcastIconMobilePath from '../data/images/podcast-icon-mobile.svg'
import RH from './RH'
import Text from './Text'

const Wrapper = styled.div`
  ${({ theme: { colors }, bgColor }) => css`
    display: flex;
    flex-direction: column;
    max-width: 640px;
    align-self: center;
    margin: 0 -16px;

    ${up('mobile')} {
      margin: 0;
    }
  `}
`

const quizBox = {
  id: 'quiz',
  title:
    'Cirkus, nebo královská rodina? Zkuste si v kvízu, jaký seriál připomíná vaše firma',
  leftIconPath: questionMarkPath,
  isQuiz: true,
  to: Link.QUIZ,
  bgColor: '#0B222B',
  boxLeftIconType: 'quiz',
  buttonText: 'spustit Kvíz',
  col: 2,
  colPos: 20,
  maxWidth: 288,
  listPos: 50,
}

const podcastBox = {
  id: 'podcast',
  title: 'Vyřešte spory v rodině, nebo vás to položí, radí psycholožka ',
  leftIconPath: podcastIconDesktopPath,
  leftIconMobilePath: podcastIconMobilePath,
  href:
    'https://forbes.cz/jak-ridit-i-jak-predat-rodinnou-firmu-i-v-tom-muze-poradit-psycholog/',
  bgColor: 'lightGold',
  boxLeftIconType: 'podcast',
  buttonText: 'Podcast',
  col: 2,
  colPos: 10,
  maxWidth: 288,
  listPos: 1000,
}

const partnerBox = {
  isPartner: true,
  col: 3,
  colPos: 10,
  listPos: 10,
  id: 'partner',
}

const COLS_BP = 1000

const useIsClient = () => {
  const [isClient, setClient] = useState(false)
  const key = isClient ? 'client' : 'server'

  useEffect(() => {
    setClient(true)
  }, [])

  return { isClient, key }
}

let alreadyRehydrated = false

function ArticlesSuggestions({ hp, hideQuiz, hideArticle }) {
  // const { isClient, key } = useIsClient()

  const { allMdx } = extractNodesFromData(useStaticQuery(query))

  let allArticles = allMdx.map(article => ({
    ...article,
    path: generateArticlePath(article),
    col: article.frontmatter.col,
    colPos: article.frontmatter.colPos,
    maxWidth: article.frontmatter.maxWidth,
    hpPhotoGap: article.frontmatter.hpPhotoGap,
    listPos: article.frontmatter.listPos || 1000,
    id: article.id,
  }))

  if (!hideQuiz) {
    allArticles.push(quizBox)
  }
  allArticles.push(podcastBox)

  const cols = { 1: [], 2: [], 3: [] }

  if (hp) {
    allArticles.push(partnerBox)

    allArticles = allArticles.map(a => ({ ...a, hp: true }))

    allArticles.forEach(article => {
      // console.log('article.col ', article.col)
      if (article.col) {
        cols[article.col].push({ ...article, hpCols: true })
      }
    })
  }

  if (hideArticle) {
    allArticles = allArticles.filter(a => a.path !== hideArticle)
  }

  // sort columns!
  [1, 2, 3].forEach(key => {
    cols[key].sort((a, b) => a.colPos - b.colPos > 0 ? 1 : -1)
  })

  // randomize list items
  let randomizedArticles = [...allArticles]
  randomizedArticles.sort((a, b) => a.listPos - b.listPos > 0 ? 1 : -1)
  console.log('alreadyRehydrated', alreadyRehydrated)

  if (alreadyRehydrated) {
    console.log('we are rehydrated in the past --> we can shuffle it')
    randomizedArticles = shuffleArray(allArticles)
    randomizedArticles = randomizedArticles.map((a, index) => ({
      ...a,
      listPos: a.listPos === 1000 ? index * 10 + 15 : a.listPos,
    }))
    randomizedArticles.sort((a, b) => a.listPos - b.listPos > 0 ? 1 : -1)
  }

  const [sortedArticles, setSortedArticles] = useState(randomizedArticles)

  // const mounted = useRef(false)
  useEffect(() => {
    alreadyRehydrated = true
    // if (!mounted.current) {
    //   // setSortedArticles(randomizedArticles)
    //   mounted.current = true
    // }
  }, [])

  // console.log(
  //   'allArticles',
  //   // allArticles,
  //   // key,
  //   sortedArticles.map(a => a.id),
  //   sortedArticles?.length > 0,
  //   sortedArticles?.length,
  // )

  const colsGap = <Gap gap="32px" mobileGap="12px" bp={1260} />

  return (
    <Col alignItems="center" width="100%">
      {hp && (
        <RH showAfter={hp ? COLS_BP : 'infinity'}>
          <Row alignItems="center" width="100%" maxWidth="1208px">
            <Col alignItems="flex-end" maxWidth="483px">
              {cols[1].map(a => (
                <>
                  <ArticleLinkBox {...a} />
                  {colsGap}
                </>
              ))}
            </Col>

            {colsGap}

            <Col alignItems="center" maxWidth="288px">
              {cols[2].map(a => (
                <>
                  <ArticleLinkBox {...a} />
                  {colsGap}
                </>
              ))}
            </Col>

            {colsGap}

            <Col alignItems="flex-start" maxWidth="372px">
              {cols[3].map(a => (
                <>
                  <ArticleLinkBox {...a} />
                  {colsGap}
                </>
              ))}
            </Col>
          </Row>
        </RH>
      )}

      <RH hideAfter={hp ? COLS_BP : 'infinity'}>
        <Wrapper>
          {!hp && (
            <>
              <Row>
                <Gap gap="0" mobileGap="16px" />

                <Text
                  color="gold"
                  font="public"
                  weight="700"
                  size="22px"
                  lineHeight="145%"
                >
                  Přečtěte si o inspirativních přístupech rodinných firem
                </Text>
              </Row>

              <Gap gap="16px" mobileGap="24px" />
            </>
          )}
          {sortedArticles?.length > 0 &&
            sortedArticles.map(a => (
              <Fragment key={a.id || a.path || a.to}>
                <ArticleLinkBox {...a} key={a.id} />
                <Gap gap="16px" mobileGap="8px" />
              </Fragment>
            ))}
        </Wrapper>
      </RH>
    </Col>
  )
}

export default ArticlesSuggestions

export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { isPublished: { eq: true } } }) {
      edges {
        node {
          ...MdxArticleFragment
        }
      }
    }
  }
`
